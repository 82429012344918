import { FaUsersGear } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import heroBg from '../../assets/Images/hero2_bg.png';
import heroScrollImg from '../../assets/Images/hero2_scroll_img.webp';
import heroSideImg from '../../assets/Images/hero2_side_img.webp';

const Hero2 = ({ isLink = true }) => {
  const handleScroll = () => {
    const currentPosition = window.scrollY;
    window.scrollTo({
      top: currentPosition + window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <header
      style={{
        background: `url(${heroBg})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
      }}
      className="relative flex flex-col justify-center h-[75vh] sm:h-[70vh] md:!h-[calc(100vh-3rem)] lg:max-h-[45rem] overflow-hidden"
    >
      <div className="absolute top-0 left-0 right-0 bottom-0 bg-[#11071fce] z-10"></div>
      <div className="flex flex-col md:flex-row gap-8 relative z-20 p-[5%] h-full m-auto max-w-[calc(1750px+5%)] 1700px:py-[2%]">
        <article className="w-full md:w-[60%] flex flex-col h-full">
          <h1 className="font-semibold text-3xl lg:text-4xl xl:lg:text-5xl my-4 font-Montserrat pr-8 text-white">
            50 New Positions Now Open – Welcoming Our First 25 New Hires!
          </h1>
          <p className="text-base lg:text-3xl pr-8 text-white opacity-70 font-Montserrat mb-auto lg:mb-0">
            50 new positions opened, with our first 25 new hires welcomed on
            September 30th.
          </p>

          {isLink && (
            <Link
              to="/Team-Details"
              className="fill-on-hover-btn hover:text-black font-BaiJamjuree font-semibold mt-auto w-full lg:w-fit text-center mb-8 md:mb-0"
            >
              Find Out More
            </Link>
          )}
        </article>
        <aside className="relative w-fit h-fit mr-8 ml-auto hidden md:block">
          <p className="flex gap-2 items-center px-6 py-3 font-BaiJamjuree font-semibold text-base lg:text-xl absolute top-12 md:whitespace-nowrap bg-[#1D0A38] rounded-lg text-white">
            <FaUsersGear /> Dedicated, Passionate, Expert Team
          </p>
          <img
            src={heroSideImg}
            alt="ISA team"
            className="w-auto h-auto max-h-[30rem]"
          />
          <img
            onClick={handleScroll}
            src={heroScrollImg}
            alt="Scroll"
            className="size-[120px] absolute bottom-0 right-0 cursor-pointer hover:animate-pulse"
          />
        </aside>
      </div>
    </header>
  );
};
export default Hero2;
